import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Sekcja4 from "../components/portfoliooferta"
import { Container, Row, Col, Table, Spinner } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Sekcja3 from "../components/sekcja3"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

class OfertaCpt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          items: [],
          isLoaded: false,
          price: 0,
          pricebrutto: 0,
          hours: 0
        };
      }
      componentDidMount() {
        const url = typeof window !== 'undefined' ? window.location.search : '';
       
        let engcheck = url.split('=');
        console.log(url)
       
        fetch('https://cdn.lemoon-web.pl/wp-json/oferta/v2/offer?hash=' +  engcheck[1])
          .then(res => res.json())
          .then(result => {
              let hour = 0;
              let prices = 0;
              let pricesbrutto = 0;
              console.log(result.acfs)

              result.acfs.tabela.map((item, i) => {
                hour = hour + Number(item.godziny)
              })

              prices = hour * result.acfs.stawka_netto;
              pricesbrutto = hour * (result.acfs.stawka_netto * 1.23);

              console.log(hour)
              console.log(result.acfs.stawka_netto)

            this.setState({
              isLoaded: true,
              items: result,
              hours: hour,
              price: prices,
              pricebrutto: pricesbrutto 

            });
          });
      } 
      render() {
        const { items } = this.state;
        if (!this.state.isLoaded) {
          return (
              <Layout lang="pl" pageInfo={{ pageName: "oferta" }}>
                <div className="small-banner-content"><div style={{ 
                    backgroundImage: `url(https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/banner-small.jpg)`}} className="background-small"></div>
                  <div className="text-on-banner">  
                    <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
                      <h1 className='smallbanner-title'>Oferta</h1>
                    </ScrollAnimation>
                  </div>
                  <div className='right-bottom-curve-cont'>
                    <div className="right-bottom-curve"></div>
                  </div>
                </div>
                <Container className="oferta-id">
                    <div className="oferta-id__loader">
                      <p>Trwa ładowanie twojej oferty.</p>
                      <p>Proszę czekać...</p>
                      <Spinner/>
                    </div>
                </Container>
              </Layout>
          );
        } else {
            if(typeof(items) != 'string'){
          return (
          <>
              <Helmet
                title={items.post_title}
                titleTemplate={items.post_title}
              />
            <Layout lang="pl" pageInfo={{ pageName: "oferta" }}>
                                    <div className="small-banner-content"><div style={{ 
                  backgroundImage: `url(https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/banner-small.jpg)`}} className="background-small"></div>
                  <div className="text-on-banner">  

                  <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
                  <h1 className='smallbanner-title'>Oferta</h1>
                  </ScrollAnimation>
              
                </div>
                <div className='right-bottom-curve-cont'>
                  <div className="right-bottom-curve"></div>
                </div>
                </div>
                <Container className="oferta-id">
                    <div className="oferta-id__wys oferta-id__head" dangerouslySetInnerHTML={{ __html:items.acfs.test_gora }}></div>

                    {items.acfs.tabela ?
                    <>
                      <Table className="oferta-id__tabletasks">
                        <tr>
                            <th>Zadanie</th>
                            <th>Ilość godzin</th>
                        </tr>
                        <tbody>
                          {items.acfs.tabela.map((item, i) => (
                            <tr>
                              <td>{item.zadanie}</td>
                              <td>{item.godziny}:00:00</td>
                            </tr>
                          ))}
                            <tr>
                              <td>Łącznie</td>
                              <td><strong>{this.state.hours}:00:00</strong></td>
                            </tr> 
                        </tbody>  
                      </Table>
                      <Table>
                        <tr>
                            <th>Podsumowanie</th>
                            <th>Kwota</th>
                        </tr> 
                          <tbody>
                            <tr>
                              <td>Stawka netto</td>
                              <td>{parseFloat(items.acfs.stawka_netto).toFixed(2)} zł</td>
                            </tr>
                            <tr>
                              <td>Stawka brutto</td>
                              <td>{parseFloat(items.acfs.stawka_netto * 1.23).toFixed(2)} zł</td>
                            </tr>
                            <tr>
                              <td>Kwota łączna netto</td>
                              <td><strong>{this.state.price} zł</strong></td>
                            </tr>
                            <tr>
                              <td>Kwota łączna brutto</td>
                              <td><strong>{Math.round(this.state.pricebrutto)} zł</strong></td>
                            </tr>
                          </tbody>
                      </Table>
                    </>
                    :
                    <></>
                    }
                </Container>
                {/* <Sekcja3 oferta="custom" sec3={items.acfs}/> */}
                <StaticQuery
                  query={ graphql`
                  query awwewcxzxcjfgvgewe {
                    wordpressPage(title: {eq: "Home"}) {
                      acf {
                        home_title_seo
                        home_description_seo
                        home_img_seo {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_1_text
                        section_1_title
                        section_2_button
                        section_2_list_sign
                        section_2_subtitle
                        section_2_text
                        section_2_title
                        section_3_list_rep {
                          text
                          title
                        }
                        section_3_img {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_2_list_rep {
                          text
                        }
                        section_2_img_front {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_2_img_behind {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_3_text
                        section_3_title
                        section_5_cloud_text
                        section_5_list_2_img {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_5_list_2_rep {
                          text
                        }
                        section_5_list_rep {
                          subtitle
                          title
                        }
                        section_5_subtitle
                        section_5_title
                        section_6_list_2_img {
                          localFile {
                            url
                          }
                          alt_text
                        }
                        section_6_list_2_rep {
                          text
                        }
                        section_6_list_rep {
                          text
                          title
                        }
                        section_6_text
                        section_6_title
                        section_1_rep {
                          text
                          img_flat
                          link
                        }
                        section_banner_button
                        section_banner_title_large
                        section_banner_title_small
                        section_banner_img {
                          localFile {
                            url
                          }
                          alt_text
                        }
                      }
                    }
                  }
                
                
                  `}
                  render={data => (
                    <Sekcja3 lang="pl" sec3={data.wordpressPage.acf}/>
                  )}
                />
                <Sekcja4 oferta={items.acfs.portfolio}></Sekcja4>
            </Layout>
          </>
          );
            }else{
                return (
                <Layout lang="pl" pageInfo={{ pageName: "oferta" }}>
                                        <div className="small-banner-content"><div style={{ 
          backgroundImage: `url(https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/banner-small.jpg)`}} className="background-small"></div>
          <div className="text-on-banner">  

          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h1 className='smallbanner-title'>Oferta</h1>
          </ScrollAnimation>
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>
                <Container>
                    <h2>Nie znaleziono oferty</h2>
                </Container>
                <Sekcja4></Sekcja4>
            </Layout>
             );
            }
        }
      }
    }
export default OfertaCpt;

