import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap';
import Filterizr from 'filterizr';

class SectionGallery extends React.Component {
  componentDidMount() {
    console.log(this.props.selected);
     const options = {
        animationDuration: 0.5, // in seconds
        callbacks: {
          onFilteringStart: function() { },
          onFilteringEnd: function() { },
          onShufflingStart: function() { },
          onShufflingEnd: function() { },
          onSortingStart: function() { },
          onSortingEnd: function() { }
       },
         delay: 100,
  delayMode: 'progressive',
  easing: 'ease-out',
       filter: 'all',
       layout: 'sameSize',
  gutterPixels: 0,
  filterOutCss: {
    opacity: 0,
    transform: 'scale(0.5)'
       },
  filterInCss: {
    opacity: 1,
    transform: 'scale(1)'
  },
      
      }

    const filterizr = new Filterizr(".filter-container", options);
  }

  excert(text){
    text = text.replace(/<\/?[^>]+(>|$)/g, "");
    text = text.substring(0,  50) + '...';
    return text;
    }

  clickonGallery(event) {
     [...document.getElementsByClassName("gallerytab")].forEach(
      (element, index, array) => {
       element.classList.remove("active");
      }
    );
     event.target.classList.toggle("active");
  }
  getShitContent(){
  const gals = this.props.gal.map((val, index) =>   
        <Link to={"/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
          <div className='gallery-img'>
        <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
        </div>
        {/* <div className='gallery-img-2'>       
            <img key={index} src={val.acf.imgmain.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
          </div> */}
        {/* <div className='gallery-text-box'><div className='gallery-text'><span>{this.excert(val.acf.tekstmain)}</span></div></div> */}
        </div>
        </Link>
       );
       return gals;
  }
  getShitContenten(){
    const gals = this.props.gal.map((val, index) =>
          <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
              {val.wordpress_id}
            <div className='gallery-img'>
          <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
          </div>
          {/* <div className='gallery-img-2'>       
            <img key={index} src={val.acf.imgmain.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
          </div> */}
          {/* <div className='gallery-text-box'><div className='gallery-text'><span>{this.excert(val.acf.tekstmain)}</span></div></div> */}
          </div>
          </Link>
         );
         return gals;
    }

  getCon(){
    const con = this.props.gal.map((val, index) => {
      return (
        this.props.selected.map((valu, indexu) => {
          if(val.wordpress_id === valu) {
            return (
              <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
                <div className='gallery-img'>
                  <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
                </div>
              </div>
              </Link>
            );
          }
        })
      )})
      return con;
    }

    render() {

      return (
          <>
          <Container>
            <Row className='gallery-head-row'>
            {this.props.lang === 'en' ?
              <>
              <h2 className='title'>OUR PROJECTS<span className='dot'>.</span></h2>
              <div className='gallery-title-box'>
              </div>
              </>
            :
            <>
                <h2 className='title'>ZOBACZ NASZE PRZYKŁADOWE PROJEKTY<span className='dot'>.</span></h2>
                <div className='gallery-title-box'>
                </div>
            </>
            }
            
            </Row>
            </Container>
            <Container fluid={true}>
              <div className="filter-container">
                {this.props.lang === 'pl' ? this.getShitContent() : this.getCon() }
              </div>
              </Container>     
          </>
      );
    }
  }
  


export default SectionGallery;
